$(document).ready(function() {

  if ($('#save-event').length) {

    $('#save-event').click(function(){
      $("#create-event").submit()
    });
  }

  if($('#edit-event').length) {

    $('#edit-event').click(function(){
      $(".btn-edit-event").submit()
    });
  }

  // import
  if ($('#import-button').length) {
    $('#import-button').click(function() {
      $('#file-import').click();
    });

    $('#file-import').change(function() {
      $('#import-form').submit();
    });

    var textTime = function(){
      $('.text-error').remove();
      };
    setTimeout(textTime , 4000);
  }

  // create  edit reception pattern
  if ($('#save-reception-pattern').length) {

    $('#save-reception-pattern').click(function(){
      $("#create-reception_pattern").submit()
    });
  }

  if($('#edit-reception_pattern').length) {

    $('#edit-reception_pattern').click(function(){
      $("#edit-reception-pattern-form").submit()
    });
  }

});
