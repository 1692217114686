$(document).ready(function () {
    let qr_form = $('#qr-form')
    if (qr_form.length) {
        qr_form.on('submit', function (event) {
            let progressBar = $('.progress-container ')
            progressBar.removeClass('d-none')
        });

        $('.form-check-input').not('#qr_gate_number').on('click', function() {
            $('#quantity').prop('disabled', true);
        });

        $('#qr_gate_number').on('click', function() {
            if ($(this).is(':checked')) {
                $('#quantity').prop('disabled', false);
            } else {
                $('#quantity').prop('disabled', true);
            }
        });
        $('.sl-edit').on('input', function() {
            var inputValue = $(this).val();
            if (inputValue < 0) {
                $(this).val(0);
            }
        });

        $('.btn-qr-form').hover(
            function() {
                $(this).css('background-color', '#606981');
                $(this).css('color', '#FFFFFF');
            },
            function() {
                $(this).css('background-color', '#FFFFFF');
                $(this).css('color', '#606981');
            }
        );
    }
});
