$(document).ready(function() {
  var receptionId;
  var receptionStatus;
  var numberOfTicket;

  $('.update-reception-status-show').on('click', function() {
    receptionId = $(this).data('reception-id');
  });

  $('#reception-status-select').on('change', function() {
    receptionStatus = $(this).val();
    var numberOfTicketInput = $('#number-of-ticket');

    if (receptionStatus === 'stop_receiving') {
      numberOfTicketInput.prop('disabled', true);
    } else {
      numberOfTicketInput.prop('disabled', false);
    }
  });

  $('#update-reception-status').on('click', function() {
    var errorNumberOfTicket = $('#error-input-number');
    numberOfTicket = $('#number-of-ticket').val();

    if ((receptionStatus === '0' || receptionStatus === '2') && (isNaN(numberOfTicket) || parseFloat(numberOfTicket) <= 0)) {
      errorNumberOfTicket.textContent = "入力した番号は無効です";
      handleError(errorNumberOfTicket);
    } else {
      var requestData = { reception_status: receptionStatus };
      if (receptionStatus === '0' || receptionStatus === '2') {
        requestData.number_of_tickets = numberOfTicket;
      }

      $.ajax({
        type: 'GET',
        url: '/shop/' + getShopId() + '/events/' + getEventId() + '/receptions/' + receptionId + '/update_reception_status',
        data: requestData,
        success: function(response) {
          if (response && response.error) {
            errorNumberOfTicket.textContent = response.error;
            handleError(errorNumberOfTicket);
          } else {
            location.reload(true);
          }
        },
        error: function(error) {
        }
      });
    }
  });

  $('.edit-reception-status').on('click', function() {
    var receptionId = $(this).data('reception-id');

    $('#confirmReopenBtn').on('click', function() {
      $.ajax({
        type: 'GET',
        url: '/shop/' + getShopId() + '/events/' + getEventId() + '/receptions/' + receptionId + '/update_reception_status_open',
        success: function() {
          location.reload(true);
        },
        error: function(error) {
        }
      });
    });
  });

  function handleError(element) {
    element.removeClass('error-div');
    setTimeout(function() {
    element.addClass('error-div');
    }, 3000);
  }

  function getShopId(){
    var currentURL = window.location.href;
    var urlParts = currentURL.split('/');
    return urlParts[4];
  }

  function getEventId(){
    var currentURL = window.location.href;
    var urlParts = currentURL.split('/');
    return urlParts[6];
  }
});
