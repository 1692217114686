import consumer from './consumer';
import Toastify from 'toastify-js';

consumer.subscriptions.create({ channel: "UserReceptionsChannel" }, {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    Toastify({
      text: data.reception_id,
      duration: 5000,
      gravity: "bottom",
      position: "right",
      close: true,
      className: "custom-toast",
      onClick: function(){
        $.ajax({
          url: '/shop/' + getShopId() + '/events/' + data.event_id + '/receptions/' + data.reception_id + '/calculator_page_have_reception_id',
          method: 'GET',
          dataType: 'json',
          success: function(response) {
            if (response.success) {
              window.location.replace('/shop/' + getShopId() + '/events/' + data.event_id + '/receptions?page=' + response.page + '&reception_id=' + data.reception_id);
            }
          },
          error: function(jqXHR, textStatus, errorThrown) {
            console.log('Ajax request failed:', textStatus, errorThrown);
          }
        });
      },
    }).showToast();

    $('.custom-toast:first').append($('<div class="image-container"></div><div class="additional-div"> '+ data.reception_id +' を受付ました</div>'));
  }
});

function getShopId() {
  var currentURL = window.location.href;
  var urlParts = currentURL.split('/');
  return urlParts[4];
}
