$(document).ready(function() {
  if ($('#create-notify-form').length > 0) {
    $('#create-notify').click(function(event) {
      event.preventDefault();
      if (validateForm()) {
        $('#create-notify-form').submit();
      }
    });
  }

  if ($('.edit_notification').length > 0) {
    $('#edit-notify').click(function(event) {
      event.preventDefault();
      if (validateFormEdit()) {
        $('#edit-notify-form').submit();
      }
    });
  }

  function validateForm() {
    const errorElements = ['#title-error', '#send-user-error', '#content-error', '#date-error'];
    return validateFormHelper(errorElements);
  }

  function validateFormEdit() {
    const errorElements = ['#title-error-edit', '#send-user-error-edit', '#content-error-edit', '#date-error-edit'];
    return validateFormHelper(errorElements);
  }

  function validateFormHelper(errorElements) {
    errorElements.forEach((elementId) => $(elementId).hide());

    var title = document.querySelector('[name="notification[title]"]').value;
    var content = document.querySelector('[name="notification[content]"]').value;
    var sendUser = $('#notification_send_users').val().length;

    const yearSelect = parseInt($('#year-select').val());
    const monthSelect = parseInt($('#month-select').val());
    const daySelect = parseInt($('#day-select').val());
    const hourSelect = parseInt($('#hour-select').val());
    const minuteSelect = parseInt($('#minute-select').val());

    const selectedDate = new Date(yearSelect, monthSelect, daySelect, hourSelect, minuteSelect);
    const currentDate = new Date();

    let hasError = false;

    if (title === '') {
      $(errorElements[0]).show();
      hasError = true;
    }

    if (sendUser === 0) {
      $(errorElements[1]).show();
      hasError = true;
    }

    if (content === '') {
      $(errorElements[2]).show();
      hasError = true;
    }

    if (currentDate > selectedDate) {
      $(errorElements[3]).show();
      hasError = true;
    }

    return !hasError;
  }
});
