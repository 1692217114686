/* eslint no-console:0 */
import Rails from '@rails/ujs'
import * as ActiveStorage from "@rails/activestorage"
import 'jquery'
import 'popper.js'
import 'bootstrap'
import "bootstrap-icons/font/bootstrap-icons.css";
import 'select2'
require('@nathanvda/cocoon')
require('admin-lte');
import moment from 'moment';

import 'stylesheets/base'
import "@fortawesome/fontawesome-free/js/all";
import 'packs/events';
import 'packs/account_manager';
import 'packs/reception_qrs';
import 'packs/event_file_input';
import 'packs/hearder';
import 'packs/download_reception';
import 'packs/reception_status';
import 'packs/setimeout_event';
import 'packs/notifications';
import 'packs/report';
import "channels"

window.moment = moment;


const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
});

Rails.start()
ActiveStorage.start()
