$(document).ready(function() {
  $("#bt-new").click(function(){
    var newRow = '<tr class="new-admin">' +
      '<td class="align-middle new-admin-email" style="width: 430px;"> <input type="text" class="form-control"></td>' +
      '<td class="align-middle new-admin-role">' +
      '<select class="form-select sl-forms" >' +
      '<option value="operator">Operator</option>' +
      '<option value="user">User</option>' +
      '</select>' +
      '</td>' +
      '<td></td>' + 
      '<th><button type="button" class="btn btn-secondary new-admin-button" style="background-color: #FFFFFF; color: #606981; width: 75px;">招待</button></th>' +
      '<th ></th>' +
      '</tr>' +
      '<tr class="validate-admin"><td id="error-list" colspan="3" style="color: #fc030b"></td></tr>';

    $("tbody").append(newRow);
  });

  $("tbody").on("click", ".delete-row", function() {
    var row = $(this).closest("tr");
    var adminId = row.data("admin-id");

    $.ajax({
      url: "/shop/"+ getShopId() +"/account_managers/" + adminId,
      method: "DELETE",
      dataType: "json",
      success: function(response) {
        if (response.success) {
          console.log("Delete success");
          location.reload(true);
        }
      },
      error: function() {
        console.log("Error when send request delete");
      }
    });
  });

  $("tbody").on("change", ".form-check-input.enable-delete-cb", function() {
    var checkbox = $(this);
    var deleteButton = checkbox.closest("tr").find(".delete-row");
  
    if (checkbox.is(":checked")) {
      deleteButton.prop("disabled", false);
      deleteButton.css("background-color", "#FF0917");
    } else {
      deleteButton.prop("disabled", true);
      deleteButton.css("background-color", "#B3B5BA");
    }
  });

  $("tbody").on("click", ".new-admin-button", function() {
    var email = $(".new-admin-email").find(".form-control").val();
    var role = $(".new-admin-role").find(".sl-forms").val();
    createAdmin(email, role)
  });
  
  function removeDuplicates(array) {
    return Array.from(new Set(array));
  }

  function createAdmin(email, role) {
    $.ajax({
      type: "POST",
      url: "/shop/"+ getShopId() +"/account_managers/",
      data: { admin: { email: email, role: role } },
      success: function(data) {
        if (data.success) {
          console.log("Update success");
          location.reload(true);
        } else {
          console.log("Update false");
          console.log(data.errors);

          var errorList = document.getElementById("error-list");

          if (data && data.errors) {
            var errors = removeDuplicates(data.errors);
            var errorMessages = [];
    
            for (var key in errors) {
              if (errors.hasOwnProperty(key)) {
                errorMessages.push(errors[key]);
              }
            }
    
            errorList.innerHTML = errorMessages.join("<br>");
          }
        }
      },
      error: function(xhr, status, error) {
        console.log("Update false");
      }
    });
  }
});

$(document).ready(function() {
  $(".sl-forms.admin-role").change(function() {
    var adminId = $(this).data("admin-id");
    var newRole = $(this).val();
    updateAdminRole(adminId, newRole);
  });
});

function updateAdminRole(adminId, newRole) {
  $.ajax({
    type: "PATCH",
    url: "/shop/"+ getShopId() + "/account_managers/" + adminId,
    data: { admin: { role: newRole } },
    success: function(data) {
      console.log("Update success");
      location.reload(true);
    },
    error: function(xhr, status, error) {
      console.log("Update unsuccess", error);
    }
  });
}

function getShopId() {
  var currentURL = window.location.href;
  var urlParts = currentURL.split('/');
  return urlParts[4];
}

