$(document).ready(function() {

  if($("#result-download").length) {
    var result_download = $("#search-receptions")

    $('#result-download').click(function(){
      result_download.submit();
    });
  }

});
