$(document).ready(function() {
  var eventContainer = $('.event-container');
  var isImageDeleted = false;

  if ($('#create-event').length > 0 || $('.btn-edit-event').length > 0) {
    if ($('#uploaded-image').attr('src') !== undefined && $('#uploaded-image').attr('src') !== '' || eventContainer.data('persisted')) {
      $(".remove-image").show();
    }

    $(".file-input").change(function() {
      if (this.files && this.files[0]) {
        var reader = new FileReader();
        reader.onload = function(e) {
          $("#uploaded-image").attr("src", e.target.result).show();
          $(".remove-image").show();
        };
        reader.readAsDataURL(this.files[0]);
      }
    });

    $(".remove-image").click(function() {
      $("#uploaded-image").attr("src", '');
      $(".file-input").val('');
      $(".remove-image").hide();

      isImageDeleted = true;
      $("#is-image-deleted").val("1");
    });

    var checkbox = $('#time_end');
    var dateFields = $('.yy-year');
    var timeFields = $('.hh-hour, .mm-minutes');

    var isInitiallyChecked = checkbox.is(":checked");
    dateFields.prop('disabled', !isInitiallyChecked);
    timeFields.prop('disabled', !isInitiallyChecked);

    checkbox.change(function () {
      var isChecked = this.checked;
      dateFields.prop('disabled', !isChecked);
      timeFields.prop('disabled', !isChecked);
    });
  }
});
