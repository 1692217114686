$(document).ready(function() {
  $('.m-month, .y-year').change(function() {
    updateDaysInMonth($(this));
  });

  $('#device-authentication').change(function() {
    var checked = this.checked;

    $.ajax({
      url: `/shop/${getShopId()}/events/${getEventId()}/update_notify_status`,
      method: 'PATCH',
      dataType: 'json',
      data: { notify_status: checked ? 1 : 0 },
      success: function(response) {
        if (response.success) {
          console.log('Update success');
        }
        location.reload(true);
      },
      error: function(jqXHR, textStatus, errorThrown) {
        console.log('Ajax request failed:', textStatus, errorThrown);
      }
    });
  });

  function updateDaysInMonth(triggeringElement, isDay = false) {
    var selectedMonth = parseInt(triggeringElement.closest('.mb-4').find('.m-month').val(), 10);
    var selectedYear = parseInt(triggeringElement.closest('.mb-4').find('.y-year').val(), 10);

    var daySelect = triggeringElement.closest('.mb-4').find('.d-day');
    const dayValue = parseInt(triggeringElement.val());
    daySelect.html('<option value=""></option>');

    var daysInMonth = new Date(selectedYear, selectedMonth, 0).getDate();

    for (var i = 1; i <= daysInMonth; i++) {
      if (isDay && dayValue === i) {
        daySelect.append('<option selected="selected" value="' + i + '">' + i + '</option>');
      } else {
        daySelect.append('<option value="' + i + '">' + i + '</option>');
      }
    }
  }

  function getShopId() {
    return window.location.href.split('/')[4];
  }

  function getEventId() {
    return window.location.href.split('/')[6];
  }

  updateDaysInMonth($('select[name="event[event_date_day]"]'), true);
  updateDaysInMonth($('select[name="event[end_date_day]"]'), true);
  updateDaysInMonth($('select[name="event[use_time_day]"]'), true);
  updateDaysInMonth($('select[name="event[ticket_display_time_day]"]'), true);
  updateDaysInMonth($('select[name="event[transfer_end_time_day]"]'), true);

});
