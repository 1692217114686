$(document).ready(function () {
  var eventContainer = $('.event-container');
  var eventCheckboxes = eventContainer.find('.form-check-input');

  if (eventCheckboxes.length >= 6) {
    eventContainer.css({
      'max-height': '191px',
      'overflow-y': 'auto'
    });
  } else {
    eventContainer.css({
      'max-height': 'none',
      'overflow-y': 'hidden'
    });
  }
});

$(document).ready(function() {
  var radioButtons = document.querySelectorAll('input[type="radio"][name="flexRadioDefaultChoosenDate"]');
  if (!sessionStorage.getItem("table_result")){
    sessionStorage.setItem("table_result" , "day")
  };

  radioButtons.forEach(function(radio) {
    radio.addEventListener("change", function() {
      sessionStorage.setItem("table_result", radio.id === "flexRadioDefaultChoosenDate1" ? "day" : "hour");
    });
  });

  $('.open-report-button').click(function() {
    var selectedEvents = [];
    var newURL;

    $('.event-checkbox:checked').each(function() {
      selectedEvents.push($(this).val());
    });

    var eventIdsParam = selectedEvents.join('&event_ids[]=');

    if (selectedEvents.length === 0) {
      newURL = "/shop/" + getShopId() + "/reports" + "?table_result=" + sessionStorage.getItem("table_result");
    } else {
      newURL = "/shop/" + getShopId() + "/reports?event_ids[]=" + eventIdsParam + "&table_result=" + sessionStorage.getItem("table_result");
    }

    if (sessionStorage.getItem("choosenTime")) {
      newURL = newURL + "&choosen_time=" + sessionStorage.getItem("choosenTime");
    }

    $.ajax({
      type: 'GET',
      url: newURL,
      success: function(data) {
        window.location.href = newURL;
      }
    });
  });
});

function getShopId() {
  var currentURL = window.location.href;
  var urlParts = currentURL.split('/');
  return urlParts[4];
}

$(document).ready(function () {
  var currentURL = window.location.href;
  var urlParts = currentURL.split('/');
  if (!urlParts[5].includes("reports")) {
    sessionStorage.removeItem("choosenTime");
  }
});
